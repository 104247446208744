@media not all and (min-width: 768px) {
  .list {
    position: relative;
    padding-left: 16px;
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      content: ""; /* 用于清除默认的列表项标记 */
  
      width: 6px;
      height: 6px;
      border-radius: 50%; /* 将形状设置为圆形 */
      // background-color: #C35BFF;
      // background-image: linear-gradient(270deg, #5237FF 0%, #29F8FF 100%);
      background: linear-gradient( 270deg, #472AFF 0%, #C35BFF 100%);
    }
  }
}