.textColor {
  background-image: linear-gradient(180deg, #5237FF 0%, #29F8FF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.borderColor {
  color: #3AD1FF;
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #000, #000), linear-gradient(90deg, rgba(41, 248, 255, 1),rgba(82, 55, 255, 1));
}